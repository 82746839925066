import React, { useState, useRef, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Card, Tooltip, OverlayTrigger, Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './TravelForm.css'; // Add this line at the top of your component file
import { v4 as uuidv4 } from 'uuid';

import { IoInformationCircleOutline } from "react-icons/io5";

import logo from './logo_transparent.PNG';
import babyGiraffeImage from './babygiraffe_transparent.png'
import { Spinner } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { MdAddAPhoto, MdPhotoCamera } from 'react-icons/md'; // Importing the icon component
import moment from 'moment-timezone';
import airportsData from './airportsFF.json'; // Path to your JSON file
import { debounce } from 'lodash';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import the CSS
import geosData from './geos.json'; // Path to your JSON file








function TravelForm() {

  
  const [formData, setFormData] = useState({
    holidayInput: '',
    location: '',
    fromDate: null,
    toDate: null,
    image: null,
    flyingFrom: '', // Add flyingFrom to your formData state
    flyingFromIATA: '',
    budget: '',
    budgetDescription: '' , // Ensure this is initialized
    daysBetween: null,
    budgetOptions: []
  });

  const locations = ['Anywhere','Asia', 'Europe', 'North America', 'Australia', 'Africa', 'South America', 'Antarctica'];

  const [babyGiraffeImageLoaded, setBabyGiraffeImageLoaded] = useState(false);

  const [selectedImage, setSelectedImage] = useState(null);


  const [uploadProgress, setUploadProgress] = useState(0);
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');

  //to show fun facts whilst loading
  const [currentFact, setCurrentFact] = useState("");

  //to add beta release banner
  const [showBetaBanner, setShowBetaBanner] = useState(true);
  const closeBetaBanner = () => setShowBetaBanner(false);

   //to add name & email feature
   const [showUserModal, setShowUserModal] = useState(false);
   const [userName, setUserName] = useState('');
   const [userEmail, setUserEmail] = useState(''); 


  const fileInputRef = useRef(null);

  

  const funFacts = [
    "Did you know? The world's largest country, Russia, is bigger than Pluto.",
    "Australia is home to the longest fence in the world. It's called the 'Dingo Fence'.",
    "Canada has more lakes than the rest of the world's lakes combined.",
    "There are no rivers in Saudi Arabia, the largest country without a river.",
    "Vatican City is the smallest country in the world, fitting within the Italian capital of Rome.",
    "France is the most visited country in the world, with millions of tourists each year.",
    "There's a city called Rome on every continent.",
    "The shortest flight between two airports is only two minutes long, in the Scottish Orkney Islands.",
    "More than 30% of the world's airports are located in the U.S.",
    "The world's longest place name is 85 characters long, located in New Zealand.",
    "Bhutan is the only country in the world that absorbs more CO2 than it emits, making it carbon-negative.",
    "In Japan, there are more pets than children.",
    "The world's deepest postbox is in Susami Bay in Japan. It's 10 meters underwater.",
    "There's a town in Norway called Hell, and it freezes over every winter.",
    "Monaco is smaller than Central Park in New York City.",
      "The Netherlands is so safe, it imports criminals to fill jails.",
      "Iceland does not have a railway system.",
      "Lesotho, Vatican City, and San Marino are the only countries completely surrounded by one other country.",
      "Only two countries use purple in their national flags: Dominica and Nicaragua.",
      "Indonesia is made up of more than 17,000 islands.",
      "Papua New Guinea has over 850 languages, the most of any country.",
      "Liechtenstein once deployed 80 men for protecting the border and returned with 81, befriending an Italian liaison officer.",
      "The shortest war in history was between Britain and Zanzibar on August 27, 1896. Zanzibar surrendered after 38 minutes.",
      "Venezuela has the world's highest waterfall, Angel Falls, which is 3,212 feet tall.",
      "There's an annual festival in Spain where people throw tomatoes at each other, called La Tomatina.",
      "The world's oldest operating amusement park is located in Denmark.",
      "The Great Pyramid of Giza was the tallest man-made structure for over 3,800 years.",
      "The first country to allow women to vote was New Zealand, in 1893.",
      "The largest snowflake ever recorded reportedly measured 15 inches across in Montana, USA.",
      "Ethiopia follows a calendar that is seven years behind the Gregorian calendar used by most of the world.",
      "There's a pink lake in Australia called Lake Hillier.",
      "The Canary Islands are named after dogs, not birds.",
      "The Philippines consists of 7,641 islands, many of which are uninhabited.",
      "Greenland is the largest island in the world.",
      "The world's largest office building by floor area is the Pentagon in the USA.",
      "The coldest inhabited place on Earth is Oymyakon, Russia.",
      "You can swim between two tectonic plates in Iceland, in the Silfra fissure.",
      "The world's longest traffic jam occurred in Beijing, China, stretching more than 60 miles.",
      "In Sweden, there's a hotel made entirely of ice; it's rebuilt every year.",
      "Sudan has more pyramids than Egypt, yet they are smaller and less famous.",
      "There's a town in India called Santa Claus.",
      "Cats were so revered in ancient Egypt that when a family cat died, all family members would shave off their eyebrows in mourning.",
      "The first country to use postcards was Austria.",
      "Bangkok's full name is the longest city place name in the world, with 168 letters.",
      "There's a volcano in Indonesia that spews blue lava, due to high sulfur content.",
      "There's a rainforest in Alaska; the Tongass is the largest national forest in the US.",
      "In 2007, Scotland spent $125,000 devising a new slogan. The winning entry was: 'Welcome to Scotland'.",
      "The largest desert in the world is not the Sahara, but Antarctica.",
      "The Aztecs played a ritual ball game where the losers would be sacrificed to the gods.",
      "You can find a statue of Liberty in Paris that faces the one in New York."
  ];

/** For Clickable Suggestion Bubbles */

const popularSuggestions = [
{ label: "Beach Destination", text: "best beach destinations with hot weather & good food" },
{ label: "City Break", text: "city break with beautiful architecture" },
 //commenting below because people are mixing this with budget. Say choosing cheap holiday but high budget then even choosing destination.
{ label: "Cheap Holiday", text: "I am coming from . My budget is very low. Find me destinations nearby that are famous for being extremely cheap" },
{ label: "Family Holiday", text: "top family holiday spots of this year" },
{ label: "Romantic Getaway", text: "beautiful romantic getaway locations for couples" },
{ label: "Northern Lights", text: "places to see northern lights" },
{ label: "Street Food", text: "cities with the best street food" },
];

const captivatingSuggestions = [
{ label: "Hidden Gems", text: "Find me beautiful hidden gem, off the beaten path, lesser known destinations" },
{ label: "Architectural Wonders Tour", text: "Architectural Wonders Tour: I want to discover the artistry and engineering behind iconic structures and innovative designs, tracing the evolution of architecture across centuries. Find me destinations that match my criteria" },
{ label: "Stargazing Escapades", text: "Stargazing Escapades: I want to seek out the darkest skies where constellations shine brightest, and shooting stars are frequent visitors, for an unforgettable astral experience. Find me such destinations" },
{ label: "Island Hopping Safari", text: "Island Hopping Safari: I want to navigate from island to island, each with its own unique ecosystem and cultural tapestry, for a diverse exploration of natural beauty and heritage" },
{ label: "Silent Retreats", text: "Silent Retreats: “Embrace stillness in serene locations ideal for meditation and reflection, allowing you to disconnect from the world and reconnect with your inner self" },
{ label: "Extreme Sports Challenge", text: "Extreme Sports Challenge: Push the limits with adrenaline-pumping activities, whether it’s conquering towering peaks, catching giant waves, or skydiving from dizzying heights" },
{ label: "Literary Legends Tour", text: "Literary Legends Tour: Trace the settings of iconic novels and the haunts of famous writers, immersing yourself in the worlds that inspired literary masterpieces" },

  ];

const [showAllPopular, setShowAllPopular] = useState(false);
const [showAllCaptivating, setShowAllCaptivating] = useState(false);
const [selectedSuggestion, setSelectedSuggestion] = useState(null);




  const handleHolidaySuggestionClick = (suggestionText, suggestionLabel) => {
    setFormData({ ...formData, holidayInput: suggestionText });
    setSelectedSuggestion(suggestionLabel);

    //commenting below because people are mixing this with budget. Say choosing cheap holiday but high budget then even choosing destination.
    if (suggestionLabel === "Cheap Holiday") {
        if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              // Process position here
              const latitude = position.coords.latitude;
              const longitude = position.coords.longitude;
              
            // Assuming you want to insert the coordinates into the suggestion text
            const newText = suggestionText.replace('.', `from ${latitude}, ${longitude}.`);
              setFormData({ ...formData, holidayInput: newText });
            },
            (error) => {
              console.error("Error obtaining location: ", error);
              // Handle location error or set default text
              setFormData({ ...formData, holidayInput: suggestionText });
            }
          );
        } else {
          console.log("Geolocation is not supported by this browser.");
          // Handle the case where geolocation is not supported
          setFormData({ ...formData, holidayInput: suggestionText });
        }
      } else {
        // For other suggestions, use the original functionality
        setFormData({ ...formData, holidayInput: suggestionText });
      }
  
    // Then use a timeout to set the new text to allow the textarea to register the change
    setTimeout(() => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        holidayInput: suggestionText
      }));
    }, 0);
  };

/** For Clickable Suggestion Bubbles */

  
/** "Surprise Me Feature" code */

const surpriseQuestions = [
  {
    key: 'historicalFigure',
    text: "**Historical Figure Journey**\nImagine traveling with Cleopatra or Leonardo da Vinci. If you could travel with any historical figure, who would it be and where would you go? \nType in a figure to find places linked to their history. Share and discover where history can take you.",
    responsePrefix: 'Historical Figure Journey: I want to travel in the footsteps of ',
    appendText: "& explore places linked to their history"
  },
  {
    key: 'cultureFoodQuest',
    text: "**Cultural Food Quest**\nWhat’s your ultimate comfort food? Sushi, tacos, pasta?\nEnter your favorite dish, and we’ll find where in the world you can enjoy the best of it on your next trip.",
    responsePrefix: 'Cultural Food Quest: I like',
    appendText: ". Find me destinations where I can enjoy the best of it on my next trip"
  },
  {
    key: 'mysteryDestination',
    text: "**Mystery Destination Challenge**\nCan you describe your ideal vacation in three words? Adventure, relaxation, culture?\nType it in and let’s unveil a surprise destination that matches your dream!",
    responsePrefix: 'Mystery Destination Challenge: Find me destinations that offer ',
    appendText: ". This is my ideal vacation so make it exciting"
  },

  {
    key: 'artCultureDive',
    text: "**Art and Culture Dive**\nWhich art form captivates you the most? Painting, music, dance?\nChoose your muse, and find destinations rich in culture and inspiration",
    responsePrefix: 'Art and Culture Dive: Find me destinations famous for their ',
    appendText: ". It captivates me the most"
  },

  {
    key: 'timeTravelTrip',
    text: "**Time Travel Trip**\nIf you could visit any era, which would it be? The roaring ‘20s, the medieval ages, or the far future?\nShare your time period, and let’s find destinations that echo these times.",
    responsePrefix: 'Time Travel Trip: Find me destinations that echo these times: ',
    appendText: "for a journey through time"
  },

  {
    key: 'fantasyWorldExpedition',
    text: "**Fantasy World Expedition**\nEver wished to visit Hogwarts or Middle-earth?\nTell us your favorite fictional setting, and we’ll match you with real-world destinations that feel like stepping into your favorite fantasy.",
    responsePrefix: 'Fantasy World Expedition: Find me destinations feel like stepping into: ',
    appendText: ". This is my favourite fictional setting"
  },

  {
    key: 'festivalFinder',
    text: "**Festival Finder**\nWhat’s your festival vibe? Music, food, art, lanterns?\nPick a festival theme, and we’ll find the world’s most exciting celebrations for you to experience.",
    responsePrefix: 'Festival Finder: Find me destinations that are famous for  ',
    appendText: "festivals. This is my favourite festival theme"
  },

  {
    key: 'ecoFriendlyEscpaes',
    text: "**Eco-Friendly Escapes**\nDreaming of a green getaway? What does your ideal eco-friendly adventure look like?\nSolar-powered stays, wildlife conservation, or plant-based dining?\nLet us know, and discover places where you can travel sustainably.",
    responsePrefix: 'Eco-Friendly Escapes: My ideal eco-friendly adventure has to do with ',
    appendText: ". Find me destinations that matches my criteria"
  },

  {
    key: 'adventureAwaits',
    text: "**Adventure Awaits**\nAre you a thrill-seeker or in search of tranquility? Maybe a bit of both?\nTell us what adventure means to you, and we’ll match you with a destination where you can live your dream.",
    responsePrefix: 'Adventure Awaits: Find me destinations that are famous for ',
    appendText: "adventure"
  },

  {
    key: 'hiddenGemHunt',
    text: "**Hidden Gem Hunt**\nSeeking somewhere serene and secluded or quirky and unknown?\nDescribe your ideal hidden gem, and we’ll reveal destinations off the beaten path that are waiting for you.",
    responsePrefix: 'Hidden Gem Hunt: I am seeking hidden gem destinations that are ',
    appendText: ". Surprise me with the results"
  },

];

const [showModal, setShowModal] = useState(false);
const [selectedQuestion, setSelectedQuestion] = useState(null);
const [userInput, setUserInput] = useState('');


const handleSurpriseClick = () => {
  let askedQuestions = JSON.parse(localStorage.getItem('askedQuestions') || '[]');
  // If all questions have been asked, reset the list
  if (askedQuestions.length >= surpriseQuestions.length) {
    localStorage.removeItem('askedQuestions');
    askedQuestions = [];
  }
  
  const unaskedQuestions = surpriseQuestions.filter(q => !askedQuestions.includes(q.key));

  if (unaskedQuestions.length > 0) {
    const randomIndex = Math.floor(Math.random() * unaskedQuestions.length);
    setSelectedQuestion(unaskedQuestions[randomIndex]);
    setShowModal(true);
  } else {
    // This block might not be necessary anymore if resetting
    // But keeping it just in case of an edge case
    alert("Restarting same surprises.");
  }
};



const handleUserInputSubmit = () => {
  if (selectedQuestion) {
    const newAnswer = `${selectedQuestion.responsePrefix} ${userInput} ${selectedQuestion.appendText}`;
    setFormData({ ...formData, holidayInput: newAnswer });

    // Update local storage
    const askedQuestions = JSON.parse(localStorage.getItem('askedQuestions') || '[]');
    localStorage.setItem('askedQuestions', JSON.stringify([...askedQuestions, selectedQuestion.key]));

    setShowModal(false);
    setUserInput('');
  }
};

//to format & parse questions text
function parseAndFormatText(text) {
  // Split text by the new line character to maintain existing line breaks
  return text.split('\n').map((line, index) => {
    // Check if line contains the marker for bold and center
    if (line.includes("**")) {
      const boldText = line.replace(/\*\*/g, ''); // Remove the markers
      return (
        <p key={index} style={{ textAlign: 'center' }}>
          <strong>{boldText}</strong>
        </p>
      );
    }
    return <p key={index}>{line}</p>;
  });
}


/** "Surprise Me Feature" code */

  
  /* Code block to implement autocomplete airport suggestions */
  const [suggestions, setSuggestions] = useState([]);

  // Debounce search function to handle input changes for "Flying From"
  const debouncedSearch = useRef(
      debounce((searchTerm) => {
          if (!searchTerm.trim()) {
              setSuggestions([]);
              return;
          }
          const filteredSuggestions = airportsData
              .filter((airport) => 
                airport.City.toLowerCase().includes(searchTerm.toLowerCase()))
              .slice(0, 10); // Limit the number of suggestions
          setSuggestions(filteredSuggestions);
      }, 300)
  ).current;

  useEffect(() => {
      // Call debounced search function
      debouncedSearch(formData.flyingFrom);

      // Cleanup function to cancel debounced search on component unmount
      return () => {
          debouncedSearch.cancel();
      };
  }, [formData.flyingFrom, debouncedSearch]);

  const handleFlyingFromInputChange = (event) => {
      const { name, value } = event.target;
      setFormData({ ...formData, [name]: value });
  };

  const handleSuggestionClick = (city, code, country) => {
    setFormData({ ...formData, flyingFrom: `${city}, ${country}`, flyingFromIATA: `${code}` });
    setSuggestions([]);
  };

/* Code block to implement autocomplete airport suggestions */



/* Code block to implement autocomplete destination suggestions */

    // New state for destination suggestions
    const [destinationSuggestions, setDestinationSuggestions] = useState([]);

    // Debounce function for destination input
    const debouncedDestinationSearch = useRef(
        debounce((searchTerm) => {
            if (!searchTerm.trim()) {
              // setDestinationSuggestions([]);
                 // Show default suggestion if input is empty
                 setDestinationSuggestions(['Anywhere']);
                return;
            }
            // Filter locations based on the input, considering case-insensitive match
            const filteredLocations = geosData.filter(location =>
                location.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setDestinationSuggestions(filteredLocations);
        }, 300)
    ).current;

    useEffect(() => {
        // Cleanup to cancel debounced calls on component unmount
        return () => {
            debouncedDestinationSearch.cancel();
        };
    }, []);


     const handleDestinationChange = (event) => {
      const { value } = event.target;
      setFormData({ ...formData, location: value });
      
      // If the value is empty, default to "Anywhere" immediately
      if (!value.trim()) {
       // setDestinationSuggestions(geosData.filter(location => location.name === 'Anywhere'));
        setDestinationSuggestions([
          { name: 'Anywhere' },
          { name: 'Anywhere Near Me' }
        ]);
      } else {
        // Otherwise, debounce the destination search
        debouncedDestinationSearch(value);
      }
    };
    

    //To replace "Me" with user's location in "Anywhere Near Me".
    const handleDestinationSelect = (selectedName) => {
      if (selectedName === "Anywhere Near Me") {

        selectedName=`must be inside 3000 miles radius of ${formData.flyingFrom}`;
        setFormData({ ...formData, location: selectedName });
        setDestinationSuggestions([]);

        //commenting below realising some users woudn't like to share their location
        /*if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition(async (position) => {

    
            // Process position here
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            // Here, you'd normally use a reverse geocoding API to convert coordinates to a location name.
            // This is a placeholder example. Replace it with an actual API call.

            
            const userLocationName = `must be inside 3000 miles radius of ${latitude}, ${longitude}`; // This should be replaced by the location name obtained from the API.

            setFormData({ ...formData, location: userLocationName });
            setDestinationSuggestions([]);
          }, (error) => {
            console.error("Error obtaining location: ", error);
            alert("Unable to retrieve your location. Please select a different destination or enable location services.");
          });
        } else {
          alert("Geolocation is not supported by your browser. Please select a different destination.");
        }*/
      } else {
        // For any other selection, just update the state normally.
        setFormData({ ...formData, location: selectedName });
        setDestinationSuggestions([]);
      }
    };
    

    // Handler for destination input change
    /*const handleDestinationChange = (event) => {
        const { value } = event.target;
        handleInputChange('location', value); // Update the state
        setDestinationSuggestions(['Anywhere']); //new addition for default "Anywhere" destination
        debouncedDestinationSearch(value); // Call debounced search
    };*/

    /*const handleDestinationSelect = (suggestion) => {
      setFormData({ ...formData, location: suggestion });
      setDestinationSuggestions([]); // Clear suggestions
    };*/
    /*const handleDestinationSelect = (location) => {
        handleInputChange('location', location.name); // Update the state with selected location
        setDestinationSuggestions([]); // Clear suggestions
    };*/



    const handleDestinationFocus = () => {
      // Check if the input is empty and update suggestions accordingly
      if (!formData.location.trim()) {
        // Here we directly call setDestinationSuggestions with the "Anywhere" value
        setDestinationSuggestions(geosData.filter(location => location.name));
      }
    };

    // Include onFocus handler for the destination input
    /*const handleDestinationFocus = () => {
      if (!formData.location.trim()) {
          setDestinationSuggestions(['Anywhere']);
      }
  };*/

/* Code block to implement autocomplete destination suggestions */


/** Code block to implement the full budgeting feature */

const [showBudgetModal, setShowBudgetModal] = useState(false);
const [budgetOptions, setBudgetOptions] = useState([]);
const [datesFilled, setDatesFilled] = useState(false);
const budgetDropdownRef = useRef(null);

const [showDestinationChoiceModal, setShowDestinationChoiceModal] = useState(false);
const [destinationChoice, setDestinationChoice] = useState('');

const handleBudgetChange = (selectedBudget) => {
  let budgetMessage = "";
  switch (selectedBudget) {
    case 'budget':
      budgetMessage = `It must be extremely cheap place for someone coming from `;
      break;
    case 'moderate1':
      budgetMessage = `It must be moderate place for someone coming from `;
      break;
    case 'moderate2':
      budgetMessage = `It must be moderate place for someone coming from `;
      break;
    case 'expensive':
      budgetMessage = `It must be extremely expensive place for someone coming from `;
      break;
    default:
      budgetMessage = "";
  }


  // Update the state to reflect the selected option for display
  // And store the full description in a separate field or as needed
  setFormData(prevFormData => ({
    ...prevFormData,
    budgetDescription: budgetMessage, // Store the full message separately if needed
    budget: selectedBudget, // This is used for the dropdown to display the selected option
  }));

  // Show modal to ask about destination selection only if user chooses budget
  if (selectedBudget!="No Budget"){
    setShowDestinationChoiceModal(true);
  }
  
};



useEffect(() => {
  if (formData.fromDate && formData.toDate) {
    const from = moment(formData.fromDate);
    const to = moment(formData.toDate);
    const duration = moment.duration(to.diff(from));
    const days = Math.ceil(duration.asDays()); // Calculate the number of days
    setFormData(prev => ({
      ...prev,
      daysBetween: days
    }));
    setDatesFilled(days >= 3);
  } else {
    setDatesFilled(false);
    setFormData(prev => ({
      ...prev,
      daysBetween: null, // Reset to null when dates are cleared or invalid
    }));
  }
}, [formData.fromDate, formData.toDate]);

// This useEffect should trigger whenever daysBetween or adultsOption changes.
useEffect(() => {
  if (formData.daysBetween >= 3 && formData.adultsOption) {
    setBudgetOptions(calculateBudgetOptions(formData.adultsOption === '1 adult' ? 1 : 2));
  } else {
    setBudgetOptions([]); // Reset options if conditions aren't met
  }
}, [formData.daysBetween, formData.adultsOption]); // Include adultsOption if it's part of your state


const handleBudgetFocus = () => {
  if (!datesFilled) {
    alert("Please enter dates first, and ensure there is a minimum of 3 days between them.");
    budgetDropdownRef.current.blur(); // To prevent popup from showing repeatedly
    return;
  }
  if (!formData.flyingFrom) {
    alert("Please enter Dates & Flying From first, and ensure there is a minimum of 3 days between dates.");
    budgetDropdownRef.current.blur(); // To prevent popup from showing repeatedly
    return;
  }
  if (!budgetOptions.length && !formData.budget) {
    setShowBudgetModal(true);
  }
};

// Calculate the budget options dynamically based on the number of days
const calculateBudgetOptions = (numAdults) => {
  const baseRates = numAdults === 1 ? [71.4, 142.85, 285.71, 500] : [107.14, 214.28, 428.57, 750];
  const optionValues = ['budget', 'moderate1', 'moderate2', 'expensive']; // Identifiers for each option

  return baseRates.map((rate, index) => {
    const nextRate = index < baseRates.length - 1 ? baseRates[index + 1] - 0.01 : '+';
    const roundedRate = Math.ceil(rate * formData.daysBetween); // Round up the current rate
    const roundedNextRate = index < baseRates.length - 1 ? Math.ceil(nextRate * formData.daysBetween) : '+';

    const label = index < baseRates.length - 1 ? `$${roundedRate} - ${roundedNextRate}` : `$${roundedRate}+`;
    return { value: optionValues[index], text: label };
  });
};

// Use the above function when setting budget options
const handleBudgetOption = (option) => {
  if (option === 'none') {
    // Set the budget to a special value indicating no budget was chosen.
    setFormData(prev => ({
      ...prev,
      budget: 'No Budget', // Special value to indicate no budget selection
      budgetOptions: [] // Clearing existing budget options
    }));
    setShowBudgetModal(false);
    return;
  }
  if (!datesFilled) {
    alert("Please enter the dates first.");
    return;
  }
  // Set the option for recalculating when dates change
  setFormData(prev => ({ ...prev, adultsOption: option }));
  const newOptions = calculateBudgetOptions(option === '1 adult' ? 1 : 2);
  setBudgetOptions(newOptions);
  setShowBudgetModal(false);
};

const handleDestinationChoice = (choice) => {
  setDestinationChoice(choice);
  setShowDestinationChoiceModal(false);

  if (choice === 'Choose for me') {
    // Logic based on the selected budget
    if (['budget', 'moderate1'].includes(formData.budget)) {
      handleDestinationSelect('Anywhere Near Me');
    } else if (formData.budget === 'moderate2') {
      setFormData(prev => ({ ...prev, location: 'Anywhere' }));
    }
  }

  else if (choice === 'I will choose') {
    // Set the budget options to a non-price-based range
    setBudgetOptions([
      { value: 'budget', text: '$-$$ - Budget' },
      { value: 'moderate', text: '$$-$$$ - Moderate' },
      { value: 'expensive', text: '$$$-$$$$ - Expensive' }
    ]);

    alert("We can only estimate prices of destinations you let us choose for you. \n\nFor the rest, we only offer budget tiers that optimises your experience.");
  }

};

/** Code block to implement the full budgeting feature */


  // Handle image change
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const validTypes = ['image/png', 'image/jpeg', 'image/webp', 'image/gif'];
    const maxFileSize = 20 * 1024 * 1024; // 20MB in bytes

    if (validTypes.includes(file.type) && file.size <= maxFileSize) {
      setFormData({ ...formData, image: file });
      setSelectedImage(file);
      // Create a URL for the image to display as a thumbnail
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);

      // You can also start the upload process here and update setUploadProgress as needed

    } else {
      alert("Invalid file type or size. Please upload a PNG, JPEG, WEBP, or non-animated GIF file under 20MB.");
      event.target.value = ''; // Reset the file input
    }
  };


  const handleInputChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  //the following is to dim the background & lock the page when form is submitted
  const [isLoading, setIsLoading] = useState(false);

  // handleSubmit is crucial part. This is where input is passed to backend. DON'T TOUCH!
  const handleSubmit = async (e) => {
    e.preventDefault();

    //checking if email exists in same session, if yes then don't ask
    if (!localStorage.getItem('userEmail')) {
      setShowUserModal(true);
    } else {
      submitForm();
    }
  };

  const submitForm = async () => {

    //this will become request_id in DB & responseId in frontend
    const submissionId = uuidv4(); 

    const userId=uuidv4();

    // This line gets the current time in GMT timezone and formats it
    const visitTime = moment().tz("GMT").format("ddd MMM DD YYYY HH:mm:ss [GMT]Z");

    console.log ("visitTime: "+visitTime);

    const userAgent= navigator.userAgent;

    //for now sending loggedIn as 'N' but once user registration is enabled, will need to change the logic here
    const loggedIn='N';

    console.log ("Print Input of form: "+formData.holidayInput);
    const formPayload = new FormData();
    formPayload.append('holidayInput', formData.holidayInput);
    formPayload.append('location', formData.location);
    formPayload.append('fromDate', formData.fromDate);
    formPayload.append('toDate', formData.toDate);
    formPayload.append('submissionId', submissionId);
    formPayload.append('userId', userId);
    formPayload.append('userName', userName);
    formPayload.append('userEmail', userEmail);
    formPayload.append('visitTime', visitTime);
    formPayload.append('userAgent', userAgent);
    formPayload.append('loggedIn', loggedIn);
    formPayload.append('flyingFrom', formData.flyingFrom);
    formPayload.append('flyingFromIATA', formData.flyingFromIATA);
    formPayload.append('budget', formData.budgetDescription);

  
    // Append image file if it exists
    if (selectedImage) {
      formPayload.append('image', selectedImage);
    }

    

    setIsLoading(true); // Set loading state

    try {
        const response = await fetch('https://voyagevoyage.co.uk/voyage-backend/submit-holiday', {

     // const response = await fetch('http://localhost:3000/submit-holiday', {
            method: 'POST',
           /* headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload)*/

            body: formPayload
        });

        if (response.ok) {
            console.log('Form submitted successfully');
            // Redirect without changing isLoading to false
            window.location.href = `https://voyagevoyage.co.uk/destinations/?responseId=${submissionId}&flyingFrom=${formData.flyingFrom}&flyingFromIATA=${formData.flyingFromIATA}&loading=true`;
         // window.location.href = `http://localhost:3003/?responseId=${submissionId}&flyingFrom=${formData.flyingFrom}&flyingFromIATA=${formData.flyingFromIATA}&loading=true`;

        } else {
            console.log('Form submission failed');
            setIsLoading(false); // Reset loading state only if submission fails
        }
    } catch (error) {
        console.error('Error:', error);
        setIsLoading(false); // Reset loading state in case of an error
    }
};

/** code block to submit name & email address */

const handleModalSubmit = () => {
  // Basic validation for the email and name
  if (!userEmail || !userName) {
    alert('Please fill out both the name and email fields.');
    return;
  }
  
  // Simple email validation regex
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(userEmail)) {
    alert('Please enter a valid email address.');
    return;
  }

  // Save to localStorage and proceed
  localStorage.setItem('userEmail', userEmail);
  localStorage.setItem('userName', userName);
  setShowUserModal(false);
  submitForm();
};


const handleModalClose = () => {
  setShowUserModal(false);
};

/** code block to submit name & email address */


  //to show fun facts on loading screen
  useEffect(() => {
    // Function to update the current fact
    const updateFact = () => {
      try {

        const randomIndex = Math.floor(Math.random() * funFacts.length);
        setCurrentFact(funFacts[randomIndex]);
        
      } catch (error) {

        console.log ("Fun Fact updateFact() method error: "+error);
        
      }
      
    };

    // Update the fact immediately and then set an interval
    updateFact();
    const intervalId = setInterval(updateFact, 6000); // Change every 6 seconds

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

// Check if the text area should be required
const isTextAreaRequired = selectedImage === null;

useEffect(() => {
  const image = new Image();
  image.src = babyGiraffeImage; // The path to your image
  image.onload = () => {
    // When the image is loaded, update the state
    setBabyGiraffeImageLoaded(true);
  };
}, []); // Empty dependency array means this effect will only run once




  if (isLoading) {
    return (
      
      <Container className="holiday-destinations-container">
        
        
        {/* Skeleton Screen */}
        
        <Row className="justify-content-center mt-3">
        <Col md={6} className="text-center">
             <a href="https://voyagevoyage.co.uk">
              <img src={logo} alt="Voyage Logo" className="mb-4" style={{ maxWidth: '150px' }} />
            </a>
        </Col>
      </Row>
        {Array.from({ length: 3 }).map((_, index) => (
          <Row key={index} className="justify-content-center mt-3">
            <Col md={6}>
              <Card>
                <Skeleton height={600} />
                <Card.Body>
                  <Skeleton height={40} />
                  <Skeleton count={6} />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ))}
        
     

        {/* Baby Giraffe Image and Text in Center */}
        <div style={{
          position: 'fixed', // Fixed position
          top: '50%', // Center vertically
          left: '50%', // Center horizontally
          transform: 'translate(-50%, -50%)', // Adjust for exact center
          textAlign: 'center', // Center the text
          zIndex: 1000, // Ensure it's above other elements
        }}>

        {babyGiraffeImageLoaded && <img src={babyGiraffeImage} alt="Baby Giraffe" style={{ maxWidth: '150px' }} />}
         
         
          <div style={{
          position: 'fixed', // Fixed position
          fontWeight: 'bold' // Make text bold
    
        }}>
          <p>{currentFact}</p>
          </div>
        </div>



      </Container>
    );
  }

else {
  return (

    
    <Container className="mt-5">
      
      <Row className="justify-content-md-center">
      
      {showBetaBanner && (
        <div className="beta-banner">
          <div className="beta-banner-content">
            <strong>Welcome to MVP of Voyage - AI Travel Search Engine which is under development!</strong> Feel free to provide any feedback at 
            <a href="mailto:ahsan@voyagevoyage.co.uk"> ahsan@voyagevoyage.co.uk</a>.
            <button type="button" className="beta-banner-close" onClick={closeBetaBanner} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      )}

        
        <Col xs={12} md={8} lg={6}>
          <div className="text-center">



            {/* Replace 'path-to-your-logo-image' with the actual path */}
            
          <img src={logo} alt="Voyage Logo" className="mb-4" style={{ maxWidth: '150px' }} />

          <p className="text-modern-sleek">
          <p>Assisting you in your vacation search using AI & Machine Learning.</p> 
          <p>Plan your next vacation within your budget to the right holiday destination using Voyage - AI travel search engine, travel guide, & AI itinerary planner.</p>
        </p>
        <h3 className="sub-heading">How does it work?</h3>
        <p>Use it like a search engine. Simply, enter the type of holiday you're looking for in the search bar. For example:</p>
        <div className="list-container">
        <ul className="holiday-types-list">
          <li>Romantic getaway in Paris</li>
          <li>Find me destinations with beautiful scenery, nice beaches, warm weather, ancient history, & delicious food</li>
          <li>Destinations famous for weddings then select "Destination: Europe"</li>
          <li>Lisbon then select "Destination: Lisbon" (if you know the destination but not sure what you're looking for) </li>
          <li>Renaissance, culture, beautiful architecture, good food then select "Destination: Europe"</li>
          <li>Try one of our holiday suggestions in “Popular” & “Captivating” categories</li>
          <li>Play our travel game “Surprise Me”</li>
          <li>Upload a picture if you don't know the destination. We will locate it for you & get you there</li>


        </ul>
        </div>

       {/* <h3 className="sub-heading">Still not sure where to go for your next holiday?</h3>
        <div className="list-container">
        <ul className="holiday-types-list">
        <li>Try one of our suggestions in the "Popular" or "Captivating" categories or try our "Surprise Me" feature to help you find exotic destinations.</li> 
        <li>You can also upload a picture & we will tell you where the picture was taken to help you plan your next holiday there.</li>     

        </ul>
       </div> */}
           
          </div>
          <Form onSubmit={handleSubmit} className="travel-form">
          <div className="textarea-button-container">
            <Form.Group className="mb-3 position-relative">
            <Form.Control
                as="textarea"
                placeholder='e.g. Romantic getaway in Paris - Destinations with beautiful scenery & beaches - Lisbon
                - Try "Surprise Me"
                - Upload a Picture'
                className="textarea-with-icon"
                value={formData.holidayInput} // Bind textarea value to state
                onChange={(e) => setFormData({ ...formData, holidayInput: e.target.value })}
                required={isTextAreaRequired} // Dynamically set required attribute
                rows={3} // Increase this value to increase default height
              />
              {imagePreviewUrl && (
                <div>
                  <img src={imagePreviewUrl} alt="Preview" style={{ maxWidth: '100px', maxHeight: '100px' }} />
                  <div style={{ width: `${uploadProgress}%`, backgroundColor: 'blue', height: '5px' }} />
                </div>
              )}
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileInputChange}
                //.webp can be accepted in future but for now no
                accept=".png, .jpg, .jpeg, .gif"
                style={{ display: 'none' }}
              />
              <MdPhotoCamera
                className="icon-button"
                onClick={() => fileInputRef.current.click()}
                size={30} // You can adjust the size if needed
              />
            </Form.Group>

            
              {/**Surprise Me Feature */}
              <>
              <Button className="surpriseme-button" onClick={handleSurpriseClick}>
              Surprise me
              </Button>

              <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                {selectedQuestion && parseAndFormatText(selectedQuestion.text)}
                <input
                  type="text"
                  value={userInput}
                  onChange={(e) => setUserInput(e.target.value)}
                />
              </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => setShowModal(false)}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={handleUserInputSubmit} style={{ backgroundColor: '#ffd88f', borderColor: '#ffd88f', color: '#000'}} className="submit-answer-button">
                  Submit Answer
                </Button>

                </Modal.Footer>
              </Modal>
              </>
              {/**Surprise Me Feature */}
              </div>
            
              <Form.Group className="mb-3">      
              {/**Code for Holiday Suggestions */}
              {/* Popular Section */}
              <div className="suggestion-section">
                <div className="suggestion-heading">Popular</div>
                {popularSuggestions.slice(0, showAllPopular ? popularSuggestions.length : 3).map((suggestion, index) => (
                  <Button
                    key={index}
                    variant="outline-secondary"
                    onClick={() => handleHolidaySuggestionClick(suggestion.text, suggestion.label)}
                    className={`suggestion-button ${selectedSuggestion === suggestion.label ? 'selected' : ''}`}
                  >
                    {suggestion.label}
                  </Button>
                ))}
                {/* Arrow for more suggestions */}
                <button type="button" className={`show-more-button ${showAllPopular ? 'show-all-popular' : ''}`} onClick={() => setShowAllPopular(!showAllPopular)}>
                  <i className="fas fa-chevron-down"></i>
                </button>
              </div>

              {/* Captivating Section */}
              <div className="suggestion-section">
                <div className="suggestion-heading">Captivating</div>
                {captivatingSuggestions.slice(0, showAllCaptivating ? captivatingSuggestions.length : 3).map((suggestion, index) => (
                  <Button
                    key={index}
                    variant="outline-secondary"
                    onClick={() => handleHolidaySuggestionClick(suggestion.text, suggestion.label)}
                    className={`suggestion-button ${selectedSuggestion === suggestion.label ? 'selected' : ''}`}
                  >
                    {suggestion.label}
                  </Button>
                ))}
                {/* Arrow for more suggestions */}
                <button type="button" className={`show-more-button ${showAllCaptivating ? 'show-all-popular' : ''}`} onClick={() => setShowAllCaptivating(!showAllCaptivating)}>
                  <i className="fas fa-chevron-down"></i>
                </button>
              </div>

              {/**Code for Holiday Suggestions */}
              </Form.Group>

            
            <Form.Group className="mb-3">
            <Form.Control
                type="text"
                name="flyingFrom"
                autoComplete="off"
                value={formData.flyingFrom}
                onChange={handleFlyingFromInputChange}
                placeholder="Flying from"
                required
            />
            {suggestions.length > 0 && (
                <ul className="flying-suggestions">
                    {suggestions.map((suggestion, index) => (
                        <li key={index} onClick={() =>handleSuggestionClick(suggestion.City, suggestion.Code, suggestion.Country)}>
                        {`${suggestion.City} (${suggestion.Code}), ${suggestion.Country}`}
                        </li>
                    ))}
                </ul>
            )}
          </Form.Group>


          <Row>
              <Col xs={6}>
                <Form.Group className="mb-3">
                  <DatePicker
                    selected={formData.fromDate}
                    onChange={(date) => handleInputChange('fromDate', date)}
                    className="form-control form-control-lg"
                    dateFormat="dd-MM-yyyy"
                    placeholderText="From"
                    minDate={new Date()} // Disables past dates
                    required
                  />
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Group className="mb-3">
                  <DatePicker
                    selected={formData.toDate}
                    onChange={(date) => handleInputChange('toDate', date)}
                    className="form-control form-control-lg"
                    dateFormat="dd-MM-yyyy"
                    placeholderText="To"
                    minDate={formData.fromDate || new Date()} // Disables past dates and any dates before fromDate
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

        {/* Budget Dropdown */}
          <Form.Group className="mb-3 d-flex align-items-center">
          <Form.Control
            as="select"
            defaultValue=""
            ref={budgetDropdownRef}
            required
            disabled={formData.daysBetween !== null && formData.daysBetween < 3 && formData.flyingFrom} // Only disable if daysBetween is calculated and less than 3

            value={formData.budget || ''} // Ensures the placeholder shows when no budget is selected
            onChange={(e) => {
              if (e.target.value) { // Prevents setting the non-value as an actual value
                handleBudgetChange(e.target.value);
              }
            }}
            onFocus={() => { if (!budgetOptions.length) handleBudgetFocus(); }}
          >
            {/* This option is now purely visual and non-selectable */}
            {(!formData.budget || formData.budget === '') && (
              <option value="" disabled style={{ display: 'none' }}>Budget Range</option>
            )}
            
            
            {formData.budget === 'No Budget' && <option value="No Budget">No Budget</option>}
            {budgetOptions.map(option => (
              <option key={option.value} value={option.value}>{option.text}</option>
            ))}
          </Form.Control>
          <OverlayTrigger
              placement="bottom"
              overlay={
              <Tooltip> Estimated prices
              </Tooltip>}
            >
              <span style={{ cursor: "pointer", marginLeft: "20px" }}>
              <IoInformationCircleOutline />
              </span>
        </OverlayTrigger>
        </Form.Group>
          {/* Modal for budget selection */}
          <Modal show={showBudgetModal} onHide={() => setShowBudgetModal(false)}>
            <Modal.Header>
              <Modal.Title>Choose Budget</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Do you want prices for 1 or 2 adults? If you don't have a budget in mind, press "No Budget".<br/><br/>
              <b>Please note the prices you will see are estimates & for guidance only.<br/>
              Baggage fee is not included.<br/>
              It assumes you're travelling at least a week in advance.</b>
            </Modal.Body>
            <Modal.Footer>
              <Button style={{ backgroundColor: '#ffd88f', borderColor: '#ffd88f', color: '#000'}} className="submit-answer-button" variant="secondary" onClick={() => handleBudgetOption('1 adult')}>1 Adult</Button>
              <Button style={{ backgroundColor: '#ffd88f', borderColor: '#ffd88f', color: '#000'}} className="submit-answer-button" variant="secondary" onClick={() => handleBudgetOption('2 adults')}>2 Adults</Button>
              <Button variant="secondary" onClick={() => handleBudgetOption('none')}>No Budget</Button>
            </Modal.Footer>
          </Modal>

          {/* Modal for destination selection */}
          <Modal show={showDestinationChoiceModal} onHide={() => setShowDestinationChoiceModal(false)}>
          <Modal.Body>
            Do you have a destination in mind, or would you like us to choose one for you?
          </Modal.Body>
          <Modal.Footer>
            <Button style={{ backgroundColor: '#ffd88f', borderColor: '#ffd88f', color: '#000'}} className="submit-answer-button" variant="secondary" onClick={() => handleDestinationChoice('I will choose')}>
              I will choose
            </Button>
            <Button  variant="primary" onClick={() => handleDestinationChoice('Choose for me')}>
              Choose for me (Recommended)
            </Button>
          </Modal.Footer>
          </Modal>


          <Form.Group className="mb-3">
            <Form.Control
                type="text"
                value={formData.location}
                onChange={handleDestinationChange}
                onFocus={handleDestinationFocus} // Handle focus to show default suggestion
                placeholder="Start typing your destination"
                autoComplete="off"
                required
            />

            {destinationSuggestions.length > 0 && (
              <ul className="destination-suggestions">
                {destinationSuggestions.map((suggestion, index) => (
                  <li key={index} onClick={() => handleDestinationSelect(suggestion.name)}>
                    {suggestion.name}
                  </li>
                ))}
              </ul>
            )}
          </Form.Group>

            <Button variant="warning" type="submit" className="btn-lg">
              Find Holidays
            </Button>
          </Form>
        {showUserModal && (
        <Modal show={showUserModal} onHide={handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Enter Your Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.target.value)}
                  required
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose}>
              Cancel
            </Button>
            <Button style={{ backgroundColor: '#ffd88f', borderColor: '#ffd88f', color: '#000'}} className="submit-answer-button" variant="secondary" onClick={handleModalSubmit}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      )}
        </Col>
      </Row>
      
      <footer className="text-center mt-4">
      <a href="https://voyagevoyage.co.uk/privacy-policy.html"
        className="footer-link"
        target="_blank" 
        rel="noopener noreferrer">
        Privacy Policy
      </a>
    </footer>
     
    </Container>

    
  );
}
 
}

export default TravelForm;
